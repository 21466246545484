var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    {
      class: { "has-media": _vm.product.media && _vm.product.media.length > 0 },
      attrs: { height: "100%" }
    },
    [
      _vm.product.media && !!_vm.product.media[0]
        ? [
            _c("v-hover", {
              scopedSlots: _vm._u(
                [
                  {
                    key: "default",
                    fn: function(ref) {
                      var hover = ref.hover
                      return [
                        _c(
                          "div",
                          [
                            hover && _vm.product.media[0].type === "gif"
                              ? _c("v-img", {
                                  staticClass: "gif-overlay",
                                  attrs: {
                                    src:
                                      _vm.blobDataPath +
                                      _vm.product.media[0].sources[0].url
                                  },
                                  on: { load: _vm.onImgLoad },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "placeholder",
                                        fn: function() {
                                          return [
                                            _c(
                                              "v-sheet",
                                              {
                                                staticClass:
                                                  "px-3 pt-3 pb-3 fill-height d-flex",
                                                attrs: {
                                                  color: "grey lighten-4"
                                                }
                                              },
                                              [
                                                _c("v-progress-circular", {
                                                  staticClass: "progress",
                                                  attrs: {
                                                    size: 70,
                                                    width: 7,
                                                    color: "secondary",
                                                    indeterminate: ""
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          ]
                                        },
                                        proxy: true
                                      }
                                    ],
                                    null,
                                    true
                                  )
                                })
                              : _vm._e(),
                            _c("v-img", {
                              attrs: {
                                src:
                                  _vm.blobDataPath +
                                  (_vm.product.media[0].type === "gif" &&
                                  _vm.product.media[0].poster
                                    ? _vm.product.media[0].poster.url
                                    : _vm.product.media[0].sources[0].url)
                              },
                              on: { load: _vm.onImgLoad },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "placeholder",
                                    fn: function() {
                                      return [
                                        _c(
                                          "v-sheet",
                                          {
                                            staticClass:
                                              "px-3 pt-3 pb-3 fill-height d-flex",
                                            attrs: { color: "grey lighten-4" }
                                          },
                                          [
                                            _c("v-progress-circular", {
                                              staticClass: "progress",
                                              attrs: {
                                                size: 70,
                                                width: 7,
                                                color: "secondary",
                                                indeterminate: ""
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      ]
                                    },
                                    proxy: true
                                  }
                                ],
                                null,
                                true
                              )
                            })
                          ],
                          1
                        )
                      ]
                    }
                  }
                ],
                null,
                false,
                3395822850
              )
            })
          ]
        : _vm._e(),
      _c(
        "v-card-title",
        { staticClass: "secondary--text" },
        [
          _c("div", [_vm._v(_vm._s(_vm.product.heading))]),
          _vm.product.innovation
            ? _c(
                "v-chip",
                { attrs: { color: "white" } },
                [
                  _c("v-icon", { attrs: { color: "red" } }, [
                    _vm._v(" mdi-lightbulb ")
                  ])
                ],
                1
              )
            : _vm._e(),
          _vm.product.newProduct
            ? _c(
                "v-chip",
                { attrs: { color: "green", "text-color": "white" } },
                [
                  _vm._v(
                    " " + _vm._s(_vm.DCSLanguageService.translate("New")) + " "
                  )
                ]
              )
            : _vm._e(),
          _c("ShareButtonComponent", {
            attrs: { "share-link": _vm.product.buttonLink }
          })
        ],
        1
      ),
      _c("v-divider", { staticClass: "mx-4" }),
      _c("ActionBarComponent", {
        attrs: {
          "application-link": _vm.product.applicationGuideLink,
          "product-link": _vm.product.productGuideLink,
          "share-link": _vm.product.buttonLink
        }
      }),
      _c("v-card-subtitle", [
        _vm._v(" " + _vm._s(_vm.product.description) + " ")
      ]),
      _c(
        "v-card-actions",
        [
          _c(
            "v-btn",
            {
              staticClass: "primary--text",
              attrs: {
                text: "",
                disabled: !_vm.product.buttonLink,
                href: _vm.getExploreMoreLink(_vm.product),
                target: "_blank"
              },
              on: {
                click: function($event) {
                  return _vm.customTracker(_vm.product)
                }
              }
            },
            [
              _vm._v(
                " " +
                  _vm._s(_vm.DCSLanguageService.translate("ExploreMore")) +
                  " "
              ),
              _vm.product.buttonLink
                ? _c("v-icon", [_vm._v(" arrow_right_alt ")])
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }