

















import { Component, Vue } from "vue-property-decorator";
import { DCSLanguageService } from "@/services";

const ProductGuideButtonProps = Vue.extend({
  props: {
    productLink: {
      type: String,
      default: null,
    },
    color: {
      type: String,
      default: "primary"
    }
  },
});

@Component
export default class ProductGuideButtonComponent extends ProductGuideButtonProps {
  public DCSLanguageService = new DCSLanguageService();
}
