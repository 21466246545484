












































































































































import { Component, Prop, Vue } from "vue-property-decorator";
import { DCSLanguageService } from "@/services";
import { ProductDetail } from "@/types/product-detail.interface";
import { CountryService } from "@/services/country.service";
import { DistributorService } from "@/services/strapi-content/distributor.service";
import { DistributorDetails, DistributorDetailsWithLinks } from "@/types";
import { take } from "rxjs/operators";
import { Country } from "@/types/country.interface";
import DistributorCardComponent from "@/components/distributor/DistributorCard.vue";

@Component({
  components: {
    DistributorCardComponent,
  },
})
export default class DistributorDialogComponent extends Vue {
  @Prop()
  product!: ProductDetail;

  dialog = false;
  isSubmitted = false;
  isLoading = true;

  languageService = new DCSLanguageService();
  countryService = new CountryService();
  distributorService = new DistributorService();

  selectedCountryId: string | null = null;
  selectedStateProvince: string | null = null;
  selectedPostalCode: string | null = null;

  distributors: DistributorDetailsWithLinks[] = [];
  filteredDistributors: DistributorDetailsWithLinks[] = [];

  get countries(): Country[] {
    return this.countryService.getCountries();
  }

  get statesProvinces(): string[] {
    return this.selectedCountryId ? this.countryService.getStatesProvinces(this.selectedCountryId) : [];
  }

  get postalCodeRequired(): boolean {
    return !!this.selectedStateProvince && this.distributors.some(distributor => distributor.locations.some(location => location.region.some(r => r.state === this.selectedStateProvince && r.postalCodes?.length)));
  }

  initDialog(): void {
    this.distributorService.getDistributorDetailsByLocaleWithProductLinks(this.product.id).pipe(take(1)).subscribe((data: DistributorDetailsWithLinks[]) => {
      this.distributors = data;
      this.isLoading = false;
    });
    this.selectedCountryId = this.countryService.getStoredCountryId();
    this.selectedStateProvince = this.countryService.getStoredStateProvince();
    this.selectedPostalCode = this.countryService.getStoredPostalCode();
    this.isSubmitted = false;
    this.isLoading = true;
  }

  onCountryChange(): void {
    this.selectedStateProvince = null;
    this.selectedPostalCode = null;
  }

  onStateProvinceChange(): void {
    this.selectedPostalCode = null;
  }

  onSubmit(): void {
    this.countryService.saveCountryId(this.selectedCountryId);
    this.countryService.saveStateProvince(this.selectedStateProvince);
    this.countryService.savePostalCode(this.selectedPostalCode);
    this.isSubmitted = true;

    this.filteredDistributors = this.distributors.filter(distributor => {
      const country = distributor.locations.find(l => l.country === this.selectedCountryId);
      const stateProvince = country?.region.find(r => r.state === this.selectedStateProvince);

      return (
        (!this.selectedCountryId || !!country) &&
        (!this.selectedStateProvince || !!stateProvince) &&
        (!this.selectedPostalCode || stateProvince?.postalCodes.includes(this.selectedPostalCode))
      );
    });
  }
}
