var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.imageCheck()
    ? _c("v-img", {
        staticClass: "gif elevation-6 media-image-loader",
        attrs: { src: _vm.getMediaPath(_vm.media.url) },
        on: {
          load: function($event) {
            return _vm.onImgLoad(_vm.cardId, _vm.media.hash)
          }
        },
        scopedSlots: _vm._u(
          [
            {
              key: "placeholder",
              fn: function() {
                return [
                  _c(
                    "v-sheet",
                    {
                      staticClass: "px-3 pt-3 pb-3 fill-height d-flex",
                      attrs: { color: "grey lighten-4" }
                    },
                    [
                      _c("v-progress-circular", {
                        staticClass: "progress",
                        attrs: {
                          size: 70,
                          width: 7,
                          color: "secondary",
                          indeterminate: ""
                        }
                      })
                    ],
                    1
                  )
                ]
              },
              proxy: true
            }
          ],
          null,
          false,
          1292503444
        )
      })
    : _c("div", { staticClass: "video-player-container" }, [
        _c("video", { attrs: { id: "video1", controls: "" } }, [
          _c("source", {
            key: _vm.media.hash,
            attrs: {
              src: _vm.getMediaPath(_vm.media.url),
              type: _vm.media.mime
            }
          }),
          _vm._v(" Your browser doesn't support HTML5 video tag. ")
        ])
      ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }