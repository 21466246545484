var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !!_vm.applicationLink || !!_vm.productLink
    ? _c(
        "div",
        [
          _c("v-card-actions", { staticClass: "action-bar" }, [
            _c(
              "div",
              [
                _c("ApplicationGuideButtonComponent", {
                  attrs: {
                    "application-link": _vm.applicationLink,
                    color: _vm.color
                  }
                })
              ],
              1
            ),
            _c(
              "div",
              [
                _c("ProductGuideButtonComponent", {
                  attrs: { "product-link": _vm.productLink, color: _vm.color }
                })
              ],
              1
            )
          ])
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }