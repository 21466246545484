var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "product-list-wrapper" },
    [
      _c("transition", { attrs: { name: "fade", mode: "out-in" } }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.onReady,
                expression: "onReady"
              }
            ],
            staticClass: "product-list-inner-wrapper"
          },
          [
            _c("header", [
              _c("h1", [_vm._v(_vm._s(_vm.listHeaderLabel))]),
              _c("p", [_vm._v(_vm._s(_vm.listDescription))])
            ]),
            _vm.listLayoutType === "innovations"
              ? _c("Innovations", {
                  attrs: { "product-list": _vm.listProducts }
                })
              : _vm._e(),
            _vm.template && _vm.listLayoutType !== "innovations"
              ? _c("TemplateComponent", { attrs: { template: _vm.template } })
              : _vm._e(),
            _c("FooterComponent")
          ],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }