import { DCSLanguageService } from "./dcs-language.service";
import { Country } from "../types/country.interface";

export class CountryService {

    countries = [
        {
            "id": "Afghanistan", "name":"Afghanistan",
        },
        {
            "id": "Albania", "name":"Albania",
        },
        {
            "id": "Algeria", "name":"Algeria",
        },
        {
            "id": "Amer.Virgin Is.", "name":"Amer.Virgin Is.",
        },
        {
            "id": "American Samoa", "name":"American Samoa",
        },
        {
            "id": "Andorra", "name":"Andorra",
        },
        {
            "id": "Angola", "name":"Angola",
        },
        {
            "id": "Anguilla", "name":"Anguilla",
        },
        {
            "id": "Antarctica", "name":"Antarctica",
        },
        {
            "id": "Antigua Barbuda", "name":"Antigua Barbuda",
        },
        {
            "id": "Argentina", "name":"Argentina",
        },
        {
            "id": "Armenia", "name":"Armenia",
        },
        {
            "id": "Aruba", "name":"Aruba",
        },
        {
            "id": "Australia", "name":"Australia",
        },
        {
            "id": "Austria", "name":"Austria",
        },
        {
            "id": "Azerbaijan", "name":"Azerbaijan",
        },
        {
            "id": "Bahamas", "name":"Bahamas",
        },
        {
            "id": "Bahrain", "name":"Bahrain",
        },
        {
            "id": "Bangladesh", "name":"Bangladesh",
        },
        {
            "id": "Barbados", "name":"Barbados",
        },
        {
            "id": "Belarus", "name":"Belarus",
        },
        {
            "id": "Belgium", "name":"Belgium",
        },
        {
            "id": "Belize", "name":"Belize",
        },
        {
            "id": "Benin", "name":"Benin",
        },
        {
            "id": "Bermuda", "name":"Bermuda",
        },
        {
            "id": "Bhutan", "name":"Bhutan",
        },
        {
            "id": "Bolivia", "name":"Bolivia",
        },
        {
            "id": "Bonaire, Saba", "name":"Bonaire, Saba",
        },
        {
            "id": "Bosnia-Herz.", "name":"Bosnia-Herz.",
        },
        {
            "id": "Botswana", "name":"Botswana",
        },
        {
            "id": "Bouvet Island", "name":"Bouvet Island",
        },
        {
            "id": "Brazil", "name":"Brazil",
        },
        {
            "id": "Brit.Ind.Oc.Ter", "name":"Brit.Ind.Oc.Ter",
        },
        {
            "id": "Brit.Virgin Is.", "name":"Brit.Virgin Is.",
        },
        {
            "id": "Brunei Daruss.", "name":"Brunei Daruss.",
        },
        {
            "id": "Bulgaria", "name":"Bulgaria",
        },
        {
            "id": "Burkina Faso", "name":"Burkina Faso",
        },
        {
            "id": "Burundi", "name":"Burundi",
        },
        {
            "id": "C. African Rep.", "name":"C. African Rep.",
        },
        {
            "id": "Cambodia", "name":"Cambodia",
        },
        {
            "id": "Cameroon", "name":"Cameroon",
        },
        {
            "id": "Canada", "name":"Canada",
        },
        {
            "id": "Cape Verde", "name":"Cape Verde",
        },
        {
            "id": "Cayman Islands", "name":"Cayman Islands",
        },
        {
            "id": "Chad", "name":"Chad",
        },
        {
            "id": "Chile", "name":"Chile",
        },
        {
            "id": "Christmas Islnd", "name":"Christmas Islnd",
        },
        {
            "id": "Coconut Islands", "name":"Coconut Islands",
        },
        {
            "id": "Colombia", "name":"Colombia",
        },
        {
            "id": "Comoros", "name":"Comoros",
        },
        {
            "id": "Congo", "name":"Congo",
        },
        {
            "id": "Cook Islands", "name":"Cook Islands",
        },
        {
            "id": "Costa Rica", "name":"Costa Rica",
        },
        {
            "id": "Cote d'Ivoire", "name":"Cote d'Ivoire",
        },
        {
            "id": "Croatia", "name":"Croatia",
        },
        {
            "id": "Curacao", "name":"Curacao",
        },
        {
            "id": "Cyprus", "name":"Cyprus",
        },
        {
            "id": "Czech Republic", "name":"Czech Republic",
        },
        {
            "id": "Dem. Rep. Congo", "name":"Dem. Rep. Congo",
        },
        {
            "id": "Denmark", "name":"Denmark",
        },
        {
            "id": "Djibouti", "name":"Djibouti",
        },
        {
            "id": "Dominica", "name":"Dominica",
        },
        {
            "id": "Dominican Rep.", "name":"Dominican Rep.",
        },
        {
            "id": "Ecuador", "name":"Ecuador",
        },
        {
            "id": "Egypt", "name":"Egypt",
        },
        {
            "id": "El Salvador", "name":"El Salvador",
        },
        {
            "id": "Equatorial Guin", "name":"Equatorial Guin",
        },
        {
            "id": "Eritrea", "name":"Eritrea",
        },
        {
            "id": "Estonia", "name":"Estonia",
        },
        {
            "id": "Ethiopia", "name":"Ethiopia",
        },
        {
            "id": "Falkland Islnds", "name":"Falkland Islnds",
        },
        {
            "id": "Faroe Islands", "name":"Faroe Islands",
        },
        {
            "id": "Fiji", "name":"Fiji",
        },
        {
            "id": "Finland", "name":"Finland",
        },
        {
            "id": "France", "name":"France",
        },
        {
            "id": "Frenc.Polynesia", "name":"Frenc.Polynesia",
        },
        {
            "id": "French Guiana", "name":"French Guiana",
        },
        {
            "id": "French S.Territ", "name":"French S.Territ",
        },
        {
            "id": "Gabon", "name":"Gabon",
        },
        {
            "id": "Gambia", "name":"Gambia",
        },
        {
            "id": "Georgia", "name":"Georgia",
        },
        {
            "id": "Germany", "name":"Germany",
        },
        {
            "id": "Ghana", "name":"Ghana",
        },
        {
            "id": "Gibraltar", "name":"Gibraltar",
        },
        {
            "id": "Greater China (Chinese Mainland)", "name":"Greater China (Chinese Mainland)",
        },
        {
            "id": "Greater China (Hong Kong SAR)", "name":"Greater China (Hong Kong SAR)",
        },
        {
            "id": "Greater China (Macau SAR)", "name":"Greater China (Macau SAR)",
        },
        {
            "id": "Greater China (Taiwan)", "name":"Greater China (Taiwan)",
        },
        {
            "id": "Greece", "name":"Greece",
        },
        {
            "id": "Greenland", "name":"Greenland",
        },
        {
            "id": "Grenada", "name":"Grenada",
        },
        {
            "id": "Guadeloupe", "name":"Guadeloupe",
        },
        {
            "id": "Guam", "name":"Guam",
        },
        {
            "id": "Guatemala", "name":"Guatemala",
        },
        {
            "id": "Guinea", "name":"Guinea",
        },
        {
            "id": "Guinea-Bissau", "name":"Guinea-Bissau",
        },
        {
            "id": "Guyana", "name":"Guyana",
        },
        {
            "id": "Haiti", "name":"Haiti",
        },
        {
            "id": "Heard McDon.Isl", "name":"Heard McDon.Isl",
        },
        {
            "id": "Honduras", "name":"Honduras",
        },
        {
            "id": "Hungary", "name":"Hungary",
        },
        {
            "id": "Iceland", "name":"Iceland",
        },
        {
            "id": "India", "name":"India",
        },
        {
            "id": "Indonesia", "name":"Indonesia",
        },
        {
            "id": "Iraq", "name":"Iraq",
        },
        {
            "id": "Ireland", "name":"Ireland",
        },
        {
            "id": "Israel", "name":"Israel",
        },
        {
            "id": "Italy", "name":"Italy",
        },
        {
            "id": "Jamaica", "name":"Jamaica",
        },
        {
            "id": "Japan", "name":"Japan",
        },
        {
            "id": "Jordan", "name":"Jordan",
        },
        {
            "id": "Kazakhstan", "name":"Kazakhstan",
        },
        {
            "id": "Kenya", "name":"Kenya",
        },
        {
            "id": "Kiribati", "name":"Kiribati",
        },
        {
            "id": "Kosovo", "name":"Kosovo",
        },
        {
            "id": "Kuwait", "name":"Kuwait",
        },
        {
            "id": "Kyrgyzstan", "name":"Kyrgyzstan",
        },
        {
            "id": "Laos", "name":"Laos",
        },
        {
            "id": "Latvia", "name":"Latvia",
        },
        {
            "id": "Lebanon", "name":"Lebanon",
        },
        {
            "id": "Lesotho", "name":"Lesotho",
        },
        {
            "id": "Liberia", "name":"Liberia",
        },
        {
            "id": "Liechtenstein", "name":"Liechtenstein",
        },
        {
            "id": "Lithuania", "name":"Lithuania",
        },
        {
            "id": "Luxembourg", "name":"Luxembourg",
        },
        {
            "id": "Madagascar", "name":"Madagascar",
        },
        {
            "id": "Malawi", "name":"Malawi",
        },
        {
            "id": "Malaysia", "name":"Malaysia",
        },
        {
            "id": "Maldives", "name":"Maldives",
        },
        {
            "id": "Mali", "name":"Mali",
        },
        {
            "id": "Malta", "name":"Malta",
        },
        {
            "id": "Marshall Islnds", "name":"Marshall Islnds",
        },
        {
            "id": "Martinique", "name":"Martinique",
        },
        {
            "id": "Mauritania", "name":"Mauritania",
        },
        {
            "id": "Mauritius", "name":"Mauritius",
        },
        {
            "id": "Mayotte", "name":"Mayotte",
        },
        {
            "id": "Mexico", "name":"Mexico",
        },
        {
            "id": "Micronesia", "name":"Micronesia",
        },
        {
            "id": "Minor Outl.Isl.", "name":"Minor Outl.Isl.",
        },
        {
            "id": "Moldova", "name":"Moldova",
        },
        {
            "id": "Monaco", "name":"Monaco",
        },
        {
            "id": "Mongolia", "name":"Mongolia",
        },
        {
            "id": "Montenegro", "name":"Montenegro",
        },
        {
            "id": "Montserrat", "name":"Montserrat",
        },
        {
            "id": "Morocco", "name":"Morocco",
        },
        {
            "id": "Mozambique", "name":"Mozambique",
        },
        {
            "id": "Myanmar", "name":"Myanmar",
        },
        {
            "id": "N.Mariana Islnd", "name":"N.Mariana Islnd",
        },
        {
            "id": "Namibia", "name":"Namibia",
        },
        {
            "id": "Nauru", "name":"Nauru",
        },
        {
            "id": "Nepal", "name":"Nepal",
        },
        {
            "id": "Netherlands", "name":"Netherlands",
        },
        {
            "id": "New Caledonia", "name":"New Caledonia",
        },
        {
            "id": "New Zealand", "name":"New Zealand",
        },
        {
            "id": "Nicaragua", "name":"Nicaragua",
        },
        {
            "id": "Niger", "name":"Niger",
        },
        {
            "id": "Nigeria", "name":"Nigeria",
        },
        {
            "id": "Niue", "name":"Niue",
        },
        {
            "id": "Norfolk Island", "name":"Norfolk Island",
        },
        {
            "id": "North Macedonia", "name":"North Macedonia",
        },
        {
            "id": "Norway", "name":"Norway",
        },
        {
            "id": "Oman", "name":"Oman",
        },
        {
            "id": "Pakistan", "name":"Pakistan",
        },
        {
            "id": "Palau", "name":"Palau",
        },
        {
            "id": "Panama", "name":"Panama",
        },
        {
            "id": "Pap. New Guinea", "name":"Pap. New Guinea",
        },
        {
            "id": "Paraguay", "name":"Paraguay",
        },
        {
            "id": "Peru", "name":"Peru",
        },
        {
            "id": "Philippines", "name":"Philippines",
        },
        {
            "id": "Pitcairn", "name":"Pitcairn",
        },
        {
            "id": "Poland", "name":"Poland",
        },
        {
            "id": "Portugal", "name":"Portugal",
        },
        {
            "id": "Puerto Rico", "name":"Puerto Rico",
        },
        {
            "id": "Qatar", "name":"Qatar",
        },
        {
            "id": "Reunion", "name":"Reunion",
        },
        {
            "id": "Romania", "name":"Romania",
        },
        {
            "id": "Russian Fed.", "name":"Russian Fed.",
        },
        {
            "id": "Rwanda", "name":"Rwanda",
        },
        {
            "id": "S. Sandwich Ins", "name":"S. Sandwich Ins",
        },
        {
            "id": "S.Tome,Principe", "name":"S.Tome,Principe",
        },
        {
            "id": "Saint Helena", "name":"Saint Helena",
        },
        {
            "id": "Saint Lucia", "name":"Saint Lucia",
        },
        {
            "id": "Samoa", "name":"Samoa",
        },
        {
            "id": "San Marino", "name":"San Marino",
        },
        {
            "id": "Saudi Arabia", "name":"Saudi Arabia",
        },
        {
            "id": "Senegal", "name":"Senegal",
        },
        {
            "id": "Serbia", "name":"Serbia",
        },
        {
            "id": "Seychelles", "name":"Seychelles",
        },
        {
            "id": "Sierra Leone", "name":"Sierra Leone",
        },
        {
            "id": "Singapore", "name":"Singapore",
        },
        {
            "id": "Sint Maarten", "name":"Sint Maarten",
        },
        {
            "id": "Slovakia", "name":"Slovakia",
        },
        {
            "id": "Slovenia", "name":"Slovenia",
        },
        {
            "id": "Solomon Islands", "name":"Solomon Islands",
        },
        {
            "id": "Somalia", "name":"Somalia",
        },
        {
            "id": "South Africa", "name":"South Africa",
        },
        {
            "id": "South Korea", "name":"South Korea",
        },
        {
            "id": "Spain", "name":"Spain",
        },
        {
            "id": "Sri Lanka", "name":"Sri Lanka",
        },
        {
            "id": "St Kitts&Nevis", "name":"St Kitts&Nevis",
        },
        {
            "id": "St. Vincent", "name":"St. Vincent",
        },
        {
            "id": "St.Pier,Miquel.", "name":"St.Pier,Miquel.",
        },
        {
            "id": "Suriname", "name":"Suriname",
        },
        {
            "id": "Svalbard", "name":"Svalbard",
        },
        {
            "id": "Swaziland", "name":"Swaziland",
        },
        {
            "id": "Sweden", "name":"Sweden",
        },
        {
            "id": "Switzerland", "name":"Switzerland",
        },
        {
            "id": "Tajikistan", "name":"Tajikistan",
        },
        {
            "id": "Tanzania", "name":"Tanzania",
        },
        {
            "id": "Thailand", "name":"Thailand",
        },
        {
            "id": "Timor-Leste", "name":"Timor-Leste",
        },
        {
            "id": "Togo", "name":"Togo",
        },
        {
            "id": "Tokelau", "name":"Tokelau",
        },
        {
            "id": "Tonga", "name":"Tonga",
        },
        {
            "id": "Trinidad,Tobago", "name":"Trinidad,Tobago",
        },
        {
            "id": "Tunisia", "name":"Tunisia",
        },
        {
            "id": "Turkey", "name":"Turkey",
        },
        {
            "id": "Turkmenistan", "name":"Turkmenistan",
        },
        {
            "id": "Turksh Caicosin", "name":"Turksh Caicosin",
        },
        {
            "id": "Tuvalu", "name":"Tuvalu",
        },
        {
            "id": "Uganda", "name":"Uganda",
        },
        {
            "id": "Ukraine", "name":"Ukraine",
        },
        {
            "id": "United Kingdom", "name":"United Kingdom",
        },
        {
            "id": "United States", "name":"United States",
        },
        {
            "id": "Uruguay", "name":"Uruguay",
        },
        {
            "id": "Utd.Arab Emir.", "name":"Utd.Arab Emir.",
        },
        {
            "id": "Uzbekistan", "name":"Uzbekistan",
        },
        {
            "id": "Vanuatu", "name":"Vanuatu",
        },
        {
            "id": "Vatican City", "name":"Vatican City",
        },
        {
            "id": "Venezuela", "name":"Venezuela",
        },
        {
            "id": "Vietnam", "name":"Vietnam",
        },
        {
            "id": "Wallis,Futuna", "name":"Wallis,Futuna",
        },
        {
            "id": "West Sahara", "name":"West Sahara",
        },
        {
            "id": "Yemen", "name":"Yemen",
        },
        {
            "id": "Zambia", "name":"Zambia",
        },
        {
            "id": "Zimbabwe", "name":"Zimbabwe",
        }
    ] as Country[];

    statesProvinces = {
        "United States": ["Alabama", "Alaska", "Arizona", "Arkansas", "California", "Colorado", "Connecticut", "Delaware", "Florida", "Georgia", "Hawaii", "Idaho", "Illinois", "Indiana", "Iowa", "Kansas", "Kentucky", "Louisiana", "Maine", "Maryland", "Massachusetts", "Michigan", "Minnesota", "Mississippi", "Missouri", "Montana", "Nebraska", "Nevada", "New Hampshire", "New Jersey", "New Mexico", "New York", "North Carolina", "North Dakota", "Ohio", "Oklahoma", "Oregon", "Pennsylvania", "Rhode Island", "South Carolina", "South Dakota", "Tennessee", "Texas", "Utah", "Vermont", "Virginia", "Washington", "West Virginia", "Wisconsin", "Wyoming"],
        "Canada": ["Alberta", "British Columbia", "Manitoba", "New Brunswick", "Newfoundland and Labrador", "Nova Scotia", "Ontario", "Prince Edward Island", "Quebec", "Saskatchewan"],
    }

    
    public getStoredCountryId(): string | null {
        const countryId = localStorage.getItem('countryId');
        return countryId;
    }

    public saveCountryId(countryId: string | null): void {
        if (!countryId) {
            localStorage.removeItem('countryId');
            return;
        }
        localStorage.setItem('countryId', countryId);
    }

    public getCountries(): Country[] {
        return this.countries;
    }


    public getStoredStateProvince(): string | null {
        const stateProvince = localStorage.getItem('stateProvince');
        return stateProvince;
    }

    public saveStateProvince(stateProvince: string | null): void {
        if (!stateProvince) {
            localStorage.removeItem('stateProvince');
            return;
        }
        localStorage.setItem('stateProvince', stateProvince);
    }

    public getStatesProvinces(countryId: string): string[] {
        if (!countryId) return [];
        return this.statesProvinces[countryId] || [];
    }


    public getStoredPostalCode(): string | null {
        const postalCode = localStorage.getItem('postalCode');
        return postalCode;
    }

    public savePostalCode(postalCode: string | null): void {
        if (!postalCode) {
            localStorage.removeItem('postalCode');
            return;
        }
        localStorage.setItem('postalCode', postalCode);
    }
}