




















import { Component, Prop, Vue } from "vue-property-decorator";
import Masonry from "masonry-layout";
import CardComponent from "@/components/products/cards/Card.vue";

import { ProductCard } from "@/types";

@Component({
  components: {
    CardComponent,
  },
})
export default class InnovationsComponent extends Vue {
  @Prop()
  public productList!: ProductCard[];

  loaded(v: boolean): void {
    const msnry = new Masonry(".masonry", {
      itemSelector: "[class*='col-']",
    });
  }
}
