var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { attrs: { outlined: "" } },
    [
      _c(
        "v-list-item",
        { attrs: { "three-line": "" } },
        [
          _c(
            "v-list-item-content",
            [
              _c("v-list-item-title", { staticClass: "text-h5 mb-1" }, [
                _vm._v(" " + _vm._s(_vm.distributor.name) + " ")
              ]),
              _c("v-list-item-subtitle", [
                _vm._v(" " + _vm._s(_vm.distributor.description) + " ")
              ])
            ],
            1
          ),
          !!_vm.distributor.logo
            ? _c(
                "v-list-item-avatar",
                { attrs: { tile: "", size: "80" } },
                [
                  _c("v-img", {
                    attrs: {
                      contain: true,
                      src: _vm.blobDataPath + _vm.distributor.logo.url
                    }
                  })
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _c(
        "v-card-actions",
        { staticClass: "flex-wrap" },
        [
          _vm.distributor.distributorLinks.length > 0
            ? _c(
                "v-btn",
                {
                  attrs: {
                    text: "",
                    href: _vm.distributor.distributorLinks[0].url,
                    target: "_blank"
                  }
                },
                [
                  _c("v-icon", { attrs: { left: "" } }, [
                    _vm._v(" mdi-cart-outline ")
                  ]),
                  _vm._v(" Order Product ")
                ],
                1
              )
            : _vm._e(),
          _c(
            "v-btn",
            {
              attrs: {
                text: "",
                href: _vm.distributor.website,
                target: "_blank"
              }
            },
            [
              _c("v-icon", { attrs: { left: "" } }, [
                _vm._v(" mdi-truck-outline ")
              ]),
              _vm._v(" View Distributor ")
            ],
            1
          ),
          _vm.distributor.contactUrl
            ? _c(
                "v-btn",
                {
                  attrs: {
                    text: "",
                    href: _vm.distributor.contactUrl,
                    target: "_blank"
                  }
                },
                [
                  _c("v-icon", { attrs: { left: "" } }, [_vm._v(" mdi-web ")]),
                  _vm._v(" Contact Distributor ")
                ],
                1
              )
            : _vm._e(),
          _vm.distributor.phoneNumber
            ? _c(
                "v-btn",
                {
                  attrs: {
                    text: "",
                    href: "tel:" + _vm.distributor.phoneNumber
                  }
                },
                [
                  _c("v-icon", { attrs: { left: "" } }, [
                    _vm._v(" mdi-phone-outline ")
                  ]),
                  _vm._v(" " + _vm._s(_vm.distributor.phoneNumber) + " ")
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }