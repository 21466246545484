









































































import { Component, Prop, Vue } from "vue-property-decorator";
import { DistributorDetailsWithLinks } from "@/types";
import { blobDataPath } from "@/util";

@Component({})
export default class DistributorCardComponent extends Vue {
    @Prop()
    distributor!: DistributorDetailsWithLinks;

    blobDataPath = blobDataPath;
}
