var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    {
      class: {
        "has-media": _vm.product.media && _vm.product.media.length > 0,
        "unset-height": true
      }
    },
    [
      _vm.product.media && !!_vm.product.media[0]
        ? [
            _c("v-hover", {
              scopedSlots: _vm._u(
                [
                  {
                    key: "default",
                    fn: function(ref) {
                      var hover = ref.hover
                      return [
                        _c(
                          "div",
                          [
                            hover && _vm.product.media[0].type === "gif"
                              ? _c("v-img", {
                                  staticClass:
                                    "gif-overlay card-image-loader first-one",
                                  attrs: {
                                    src:
                                      _vm.blobDataPath +
                                      _vm.product.media[0].sources[0].url
                                  },
                                  on: {
                                    load: function($event) {
                                      return _vm.onImgLoad(
                                        _vm.product.media[0].sources[0].id,
                                        _vm.product.media[0].type
                                      )
                                    }
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "placeholder",
                                        fn: function() {
                                          return [
                                            _c(
                                              "v-sheet",
                                              {
                                                staticClass:
                                                  "px-3 pt-3 pb-3 fill-height d-flex",
                                                attrs: {
                                                  color: "grey lighten-4"
                                                }
                                              },
                                              [
                                                _c("v-progress-circular", {
                                                  staticClass: "progress",
                                                  attrs: {
                                                    size: 70,
                                                    width: 7,
                                                    color: "secondary",
                                                    indeterminate: ""
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          ]
                                        },
                                        proxy: true
                                      }
                                    ],
                                    null,
                                    true
                                  )
                                })
                              : _vm._e(),
                            _c("v-img", {
                              staticClass: "second-one",
                              attrs: {
                                src:
                                  _vm.blobDataPath +
                                  (_vm.product.media[0].type === "gif"
                                    ? _vm.product.media[0].poster.url
                                    : _vm.product.media[0].sources[0].url)
                              },
                              on: {
                                load: function($event) {
                                  _vm.onImgLoad(
                                    _vm.cardId,
                                    _vm.product.media[0].type === "gif"
                                      ? _vm.product.media[0].poster.hash
                                      : _vm.product.media[0].sources[0].hash
                                  )
                                }
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "placeholder",
                                    fn: function() {
                                      return [
                                        _c(
                                          "v-sheet",
                                          {
                                            staticClass:
                                              "px-3 pt-3 pb-3 fill-height d-flex",
                                            attrs: { color: "grey lighten-4" }
                                          },
                                          [
                                            _c("v-progress-circular", {
                                              staticClass: "progress",
                                              attrs: {
                                                size: 70,
                                                width: 7,
                                                color: "secondary",
                                                indeterminate: ""
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      ]
                                    },
                                    proxy: true
                                  }
                                ],
                                null,
                                true
                              )
                            })
                          ],
                          1
                        )
                      ]
                    }
                  }
                ],
                null,
                false,
                2147598827
              )
            })
          ]
        : _vm._e(),
      _c(
        "v-card-title",
        { staticClass: "secondary--text flex-1" },
        [
          _c("div", [_vm._v(_vm._s(_vm.product.heading))]),
          _vm.product.newProduct
            ? _c(
                "v-chip",
                { attrs: { color: "green", "text-color": "white" } },
                [
                  _vm._v(
                    " " + _vm._s(_vm.DCSLanguageService.translate("New")) + " "
                  )
                ]
              )
            : _vm._e(),
          _c("ShareButtonComponent", {
            attrs: { "share-link": _vm.product.buttonLink }
          })
        ],
        1
      ),
      _c("v-divider", { staticClass: "mx-4" }),
      _c("ActionBarComponent", {
        attrs: {
          "application-link": _vm.product.applicationGuideLink,
          "product-link": _vm.product.productGuideLink
        }
      }),
      _vm.videos && !!_vm.videos[0]
        ? [
            _c(
              "v-sheet",
              {
                staticClass: "mx-auto video-sheet",
                attrs: { "max-width": "530" }
              },
              [
                _c(
                  "v-slide-group",
                  {
                    attrs: { "center-active": "", "show-arrows": "" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "next",
                          fn: function() {
                            return [
                              _c("v-icon", { staticClass: "video-carrot" }, [
                                _vm._v(" mdi-chevron-right ")
                              ])
                            ]
                          },
                          proxy: true
                        },
                        {
                          key: "prev",
                          fn: function() {
                            return [
                              _c("v-icon", { staticClass: "video-carrot" }, [
                                _vm._v(" mdi-chevron-left ")
                              ])
                            ]
                          },
                          proxy: true
                        }
                      ],
                      null,
                      false,
                      901518340
                    ),
                    model: {
                      value: _vm.videos,
                      callback: function($$v) {
                        _vm.videos = $$v
                      },
                      expression: "videos"
                    }
                  },
                  _vm._l(_vm.videos.slice(0, 2), function(item) {
                    return _c(
                      "v-slide-group-item",
                      { key: item.id },
                      [
                        _c(
                          "v-card",
                          {
                            staticClass: "ma-4 video-card",
                            attrs: { height: "260", width: "430" }
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "d-flex fill-height align-center justify-center"
                              },
                              [
                                _c("VideoTemplateComponent", {
                                  attrs: { videoitem: item }
                                })
                              ],
                              1
                            )
                          ]
                        )
                      ],
                      1
                    )
                  }),
                  1
                )
              ],
              1
            )
          ]
        : _vm._e(),
      _c(
        "v-card-subtitle",
        [
          _c(
            "v-clamp",
            {
              staticClass: "product-description",
              attrs: { "max-lines": 1, autoresize: true },
              on: {
                clampchange: function($event) {
                  return _vm.resizeMasonry()
                }
              },
              scopedSlots: _vm._u([
                {
                  key: "after",
                  fn: function(ref) {
                    var toggle = ref.toggle
                    var clamped = ref.clamped
                    var expanded = ref.expanded
                    return [
                      clamped && !expanded
                        ? _c(
                            "a",
                            {
                              staticClass: "clamp-toggle-text",
                              on: { click: toggle }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.DCSLanguageService.translate("ReadOn")
                                  ) +
                                  " "
                              )
                            ]
                          )
                        : _vm._e(),
                      !clamped && expanded
                        ? _c(
                            "a",
                            {
                              staticClass: "clamp-toggle-text",
                              on: { click: toggle }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.DCSLanguageService.translate("ReadLess")
                                  ) +
                                  " "
                              )
                            ]
                          )
                        : _vm._e()
                    ]
                  }
                }
              ])
            },
            [_vm._v(" " + _vm._s(_vm.product.description) + " ")]
          )
        ],
        1
      ),
      _c(
        "v-card-actions",
        [
          _c(
            "v-flex",
            { staticClass: "explore-more-button" },
            [
              _c(
                "v-btn",
                {
                  staticClass: "primary--text",
                  attrs: {
                    text: "",
                    disabled: !_vm.product.buttonLink,
                    href: _vm.getExploreMoreLink(_vm.product),
                    target: "_blank"
                  },
                  on: {
                    click: function($event) {
                      return _vm.customTracker(_vm.product)
                    }
                  }
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(_vm.DCSLanguageService.translate("ExploreMore")) +
                      " "
                  ),
                  _vm.product.buttonLink
                    ? _c("v-icon", [_vm._v(" arrow_right_alt ")])
                    : _vm._e()
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-flex",
            { staticClass: "request-sample-button" },
            [
              _c("RequestSampleButtonComponent", {
                staticClass: "primary--text",
                attrs: {
                  "sample-title": _vm.product.sampleTitle,
                  "sample-link": _vm.product.sampleLink
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }