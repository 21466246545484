var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wrapper" },
    [
      _c(
        "v-row",
        { staticClass: "masonry mobile-innovations" },
        _vm._l(_vm.productList, function(product) {
          return _c(
            "v-col",
            { key: product.id, attrs: { cols: "12", md: "4" } },
            [
              _c("CardComponent", {
                attrs: { images: true, product: product },
                on: {
                  loaded: _vm.loaded,
                  click: function($event) {
                    return _vm.$emit("product-selected", product)
                  }
                }
              })
            ],
            1
          )
        }),
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }