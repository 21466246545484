



















import { Component, Vue } from "vue-property-decorator";

import ApplicationGuideButtonComponent from "@/components/products/buttons/ApplicationButton.vue";
import ProductGuideButtonComponent from "@/components/products/buttons/ProductButton.vue";

const ActionBarProps = Vue.extend({
  props: {
    applicationLink: {
      type: String,
      default: null,
    },
    productLink: {
      type: String,
      default: null,
    },
    color: {
      type: String,
      default: "primary"
    }
  }
})

@Component({
  components: {  
    ApplicationGuideButtonComponent,
    ProductGuideButtonComponent
  }
})
export default class ActionBarComponent extends ActionBarProps {}
