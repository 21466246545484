var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-hover", {
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function(ref) {
          var hover = ref.hover
          return [
            _c(
              "v-card",
              {
                staticClass: "ma-4 px-3 v-card--hovered",
                attrs: { elevation: "0", width: "480" },
                on: {
                  click: function($event) {
                    return _vm.showPlayerTrigger()
                  }
                }
              },
              [
                _c(
                  "div",
                  { staticClass: "row" },
                  [
                    _c("v-img", {
                      staticClass:
                        "video-poster-display video-poster-display--medium video-poster-display--hovered",
                      attrs: { src: _vm.posterSrc, "max-height": "300" }
                    })
                  ],
                  1
                ),
                _vm.showPlayer
                  ? [
                      _c(
                        "v-dialog",
                        {
                          staticClass: "video-player-dialog-container",
                          attrs: { width: "75vw" },
                          on: {
                            input: function(show) {
                              return show || _vm.$emit("player-closed")
                            }
                          },
                          model: {
                            value: _vm.showPlayer,
                            callback: function($$v) {
                              _vm.showPlayer = $$v
                            },
                            expression: "showPlayer"
                          }
                        },
                        [
                          _vm.showPlayer
                            ? _c("iframe", {
                                attrs: {
                                  sandbox:
                                    "allow-same-origin allow-scripts allow-popups allow-forms",
                                  src: _vm.videoURL,
                                  height: "500",
                                  frameborder: "0",
                                  allow:
                                    "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture",
                                  allowfullscreen: "",
                                  title: "Tr&eacute;ning TEXT"
                                }
                              })
                            : _vm._e()
                        ]
                      )
                    ]
                  : _vm._e()
              ],
              2
            )
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }