































import { Vue, Component } from "vue-property-decorator";
import { ComponentOptions } from "vue/types/options";

//import VueIntercom from "vue-intercom";
import { ReplaySubject } from "rxjs";
import { takeUntil } from "rxjs/operators";

import FooterComponent from "@/components/core/Footer.vue";
import CityDarkBGComponent from "@/components/core/backgrounds/CityDarkBG.vue";
import TemplateComponent from "@/components/products/cards/Template.vue";
import Innovations from "@/components/products/cards/Innovations.vue";

import {
  CityNavigatorService,
  LocaleService,
  DCSLanguageService,
  ProductService,
  MetaService,
} from "@/services";
import { ProductDetail, SubNavigator, Template } from "@/types";

const metaInfo: ComponentOptions<Vue>['metaInfo'] = {
  title: 'Our Latest Building Science Innovations',
  meta: [
    {
      vmid: 'description',
      name: 'description',
      content: 'Learn more about our latest innovations in Building Science. Our silicone technology can provide solutions to variety of applications.',
    },
  ],
};

export const metaInfoMixin = {
  metaInfo,
};


@Component({
  mixins: [metaInfoMixin],
  components: {
    FooterComponent,
    CityDarkBGComponent,
    TemplateComponent,
    Innovations,
  },
})
export default class ProductList extends Vue {
  private localeService = new LocaleService();
  private DCSLanguageSerivce = new DCSLanguageService();
  private metaService = new MetaService();
  // public intercom = new VueIntercom({
  //   appId: process.env.VUE_APP_INTERCOM_APP_ID,
  // });

  private xonReady = false;
  public get onReady(): boolean {
    return this.xonReady;
  }
  public set onReady(ready: boolean) {
    this.xonReady = ready;
  }

  private xtemplate: Template | null = null;
  public get template(): Template | null {
    return this.xtemplate;
  }
  public set template(template: Template | null) {
    this.xtemplate = template;
  }

  private xlistHeaderLabel = "";
  public get listHeaderLabel(): string {
    return this.xlistHeaderLabel;
  }
  public set listHeaderLabel(label: string) {
    this.xlistHeaderLabel = label;
  }

  private xlistDescription = "";
  public get listDescription(): string {
    return this.xlistDescription;
  }
  public set listDescription(label: string) {
    this.xlistDescription = label;
  }

  private xlistProducts: ProductDetail[] = [];
  public get listProducts(): ProductDetail[] {
    return this.xlistProducts;
  }
  public set listProducts(products: ProductDetail[]) {
    this.xlistProducts = products;
  }

  private xlistLayoutType = "";
  public get listLayoutType(): string {
    return this.xlistLayoutType;
  }
  public set listLayoutType(type: string) {
    this.xlistLayoutType = type;
  }

  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

  private cityNavigatorService = new CityNavigatorService();
  private productService = new ProductService();

  private created(): void {
    if (this.$route.params.categoryLabel === "innovations") {
      this.listHeaderLabel = this.DCSLanguageSerivce.translate("Innovations");
      this.listLayoutType = "innovations";

      this.productService
        .getProductListDataByInnovation()
        .pipe(takeUntil(this.destroyed$))
        .subscribe((products: ProductDetail[]) => {
          this.listProducts = products;
        });
    } else {
      const subNavigatorIdStr = this.$route.params.categoryLabel.split("-")[0];
      const subNavigatorId = Number(subNavigatorIdStr);

      if (isNaN(subNavigatorId)) {
        console.error(
          "Unable to find subnavigator.  Check to see if there is a number after /list/{numberhere}-{label}, if not there is an issue"
        );
      } else {
        this.cityNavigatorService
          .getSubNavigatorById(subNavigatorId)
          .pipe(takeUntil(this.destroyed$))
          .subscribe(async (subNavigator: SubNavigator) => {
            this.listHeaderLabel = subNavigator.labelText;
            this.listDescription = subNavigator.description;
            this.template = subNavigator.template as Template;
            if (
              subNavigator.metaDescription == undefined ||
              subNavigator.metaTitle == undefined
            ) {
              this.metaService.updateMetaDescription(subNavigator.description);
              this.metaService.updateMetaTitle(subNavigator.labelText);
            } else {
              this.metaService.updateMetaDescription(
                subNavigator.metaDescription
              );
              this.metaService.updateMetaTitle(subNavigator.metaTitle);
            }
          });
      }
    }
  }

  private mounted(): void {
    this.onReady = true;
    const locale = this.localeService.getLocale();
    // if (
    //   String(process.env.VUE_APP_ENABLE_INTERCOM) == "true" &&
    //   locale.code === "en"
    // ) {
    //   // wait 10 seconds
    //   setTimeout(() => {
    //     this.intercom.show();
    //   }, 10000);
    // }
  }

  private destroyed(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
}
