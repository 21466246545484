








































import { Component, Prop, Vue } from "vue-property-decorator";

import { StrapiMedia } from "@/types";

import { blobDataPath } from "@/util/blob-data-path.utility";

@Component
export default class MediaOnlyComponent extends Vue {
  @Prop()
  public media!: StrapiMedia;

  @Prop()
  public poster!: StrapiMedia;

  @Prop()
  public cardId!: number;

  mounted(): void {
    const videoEL = document.querySelector("#video1");
    if (videoEL && this.poster) {
      videoEL.setAttribute("poster", blobDataPath + this.poster.url);
    }
  }

  public getMediaPath(url: string): string {
    return blobDataPath + url;
  }

  public imageCheck(): boolean {
    if (this.media.mime.includes("image")) {
      return true;
    } else return false;
  }

  onImgLoad(cardId: number, hash: string): void {
    this.$emit("loaded", { cardId, hash });
  }
}
