























































































































































































import { Component, Prop, Vue } from "vue-property-decorator";
import VClamp from "vue-clamp";

import ShareButtonComponent from "@/components/products/buttons/ShareButton.vue";
import ActionBarComponent from "@/components/products/ActionBar.vue";
import VideoTemplateComponent from "@/components/media/content-media-grids/VideoTemplate.vue";
import RequestSampleButtonComponent from "@/components/products/buttons/RequestSampleButton.vue";
import DistributorDialogComponent from "@/components/distributor/DistributorDialog.vue";

import { DCSLanguageService } from "@/services";
import { ProductDetail } from "@/types";
import { VideoData } from "@/types/product-video.interface";

import { blobDataPath, getExploreMoreLink } from "@/util";


@Component({
  components: { 
    VClamp, 
    RequestSampleButtonComponent,
    ShareButtonComponent,
    ActionBarComponent,
    VideoTemplateComponent,
    DistributorDialogComponent
  }
})
export default class StandardCardComponent extends Vue {
  @Prop()
  public product!: ProductDetail;

  @Prop()
  public cardId!: number;
  
  @Prop()
  public videos!: VideoData[];

  //this is needed as there is an issue with masonry
  //not resizing after expanding of text content
  @Prop()
  public resizeMasonry!: Function;

  public blobDataPath = blobDataPath;
  public getExploreMoreLink = getExploreMoreLink;

  public DCSLanguageService = new DCSLanguageService();
  
  public showPlayer = false;

  public customTracker(product): void {
    if (product.buttonLink){
      this["$appInsights"].appInsights.trackEvent({ name: `${product.heading} - CTA Button`, properties: {page: "Product Overview Page", taggedItemType: "CTA", taggedItemText: "PRODUCT NAME - CTA Button"}});
      //eslint-disable-next-line
      (window as any).dataLayer.push({
          event: 'Link Tracking',
          link: {
              linkName: `${product.heading}`, // usually link text or other identifier
              linkPosition: 'CTA Button', // location of link on page
              linkPage: product.heading // usually `page.pageInfo.pageName` from `Page Viewed` event
          }
      })
    }
  }

  onImgLoad(cardId: number, hash: string): void {
    this.$emit('loaded', { cardId, hash });
  }
}
