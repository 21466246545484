var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-btn",
    {
      staticClass: "action-button primary--text",
      attrs: {
        text: "",
        small: "",
        href: _vm.applicationLink,
        disabled: !_vm.applicationLink,
        color: _vm.color,
        target: "_blank"
      }
    },
    [
      _c(
        "div",
        [
          _c("v-icon", [_vm._v("mdi-format-list-numbered")]),
          _vm._v(
            " " +
              _vm._s(_vm.DCSLanguageService.translate("ApplicationGuide")) +
              " "
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }