import { render, staticRenderFns } from "./StandardCard.vue?vue&type=template&id=739ed164"
import script from "./StandardCard.vue?vue&type=script&lang=ts"
export * from "./StandardCard.vue?vue&type=script&lang=ts"
import style0 from "./StandardCard.vue?vue&type=style&index=0&id=739ed164&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VHover } from 'vuetify/lib/components/VHover';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VSheet } from 'vuetify/lib/components/VSheet';
import { VSlideGroup } from 'vuetify/lib/components/VSlideGroup';
installComponents(component, {VBtn,VCard,VCardActions,VCardSubtitle,VCardTitle,VChip,VDivider,VFlex,VHover,VIcon,VImg,VProgressCircular,VSheet,VSlideGroup})


/* hot reload */
if (module.hot) {
  var api = require("/home/vsts/work/1/s/app/b-i-connect/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('739ed164')) {
      api.createRecord('739ed164', component.options)
    } else {
      api.reload('739ed164', component.options)
    }
    module.hot.accept("./StandardCard.vue?vue&type=template&id=739ed164", function () {
      api.rerender('739ed164', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/products/cards/StandardCard.vue"
export default component.exports