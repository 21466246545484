







































  import { Component, Prop, Vue } from "vue-property-decorator";
  
  import VideoPlayerComponent from "@/components/media/VideoPlayer.vue";
  
  import { blobDataPath } from "@/util/blob-data-path.utility";
import { VideoData } from "@/types/product-video.interface";
  
  @Component({
    components: {
      VideoPlayerComponent,
    },
  })
  export default class VideoTemplateComponent extends Vue {
    @Prop()
    public videoitem!: VideoData;
  
    public showPlayer = false;
  
    public showPlayerTrigger(): void {
      this.showPlayer = true;
    }
  
    public get posterSrc(): string {
      return blobDataPath + this.videoitem.thumbnail.url;
    }
    public get videoURL(): string {
      return this.videoitem.url;
    }
  }
  