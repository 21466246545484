import { render, staticRenderFns } from "./Template.vue?vue&type=template&id=7b5caf71&scoped=true"
import script from "./Template.vue?vue&type=script&lang=ts"
export * from "./Template.vue?vue&type=script&lang=ts"
import style0 from "./Template.vue?vue&type=style&index=0&id=7b5caf71&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7b5caf71",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCol,VRow})


/* hot reload */
if (module.hot) {
  var api = require("/home/vsts/work/1/s/app/b-i-connect/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('7b5caf71')) {
      api.createRecord('7b5caf71', component.options)
    } else {
      api.reload('7b5caf71', component.options)
    }
    module.hot.accept("./Template.vue?vue&type=template&id=7b5caf71&scoped=true", function () {
      api.rerender('7b5caf71', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/products/cards/Template.vue"
export default component.exports