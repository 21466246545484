


































































import { Component, Prop, Vue } from "vue-property-decorator";
import Masonry from "masonry-layout";
import StandardCardComponent from "@/components/products/cards/StandardCard.vue";
import MediaOnlyComponent from "@/components/products/cards/MediaOnly.vue";

import { Card, Template } from "@/types";
import { blobDataPath } from "@/util/blob-data-path.utility";
import { fromEvent, Subject } from "rxjs";
import { debounceTime, takeUntil, tap } from "rxjs/operators";

@Component({
  components: {
    StandardCardComponent,
    MediaOnlyComponent,
  },
})
export default class TemplateComponent extends Vue {
  @Prop()
  public template!: Template;

  public blobDataPath = blobDataPath;
  public mediaCard!: Card | null;
  public cards!: Card[];

  private mediaToLoad: string[] = [];
  private mediaLoaded: string[] = [];

  private unsubscribe = new Subject();
  private masonry: Masonry;
  private masonryTick!: NodeJS.Timeout;

  created(): void {
    for (const [index, card] of this.template.cards.entries()) {
      if (
        (card.type === "MEDIA_ONLY_TALL" || card.type === "MEDIA_ONLY_WIDE") &&
        card.media
      ) {
        this.mediaToLoad.push(`${card.id}_${card.media.hash}`);
      } else if (
        card.type === "TEXT_WITH_MEDIA" &&
        card.productDetail &&
        card.productDetail.media &&
        card.productDetail.media.length > 0
      ) {
        const hash =
          card.productDetail.media[0].type === "gif"
            ? card.productDetail.media[0].poster.hash
            : card.productDetail.media[0].sources[0].hash;
        this.mediaToLoad.push(`${card.id}_${hash}`);
      }
    }

    const [mediaCard, ...cardsWithoutMediaCard] = this.template.cards;
    this.mediaCard = mediaCard.media ? mediaCard : null;
    this.cards = mediaCard.media ? cardsWithoutMediaCard : this.template.cards;

    fromEvent(window, "resize")
      .pipe(
        debounceTime(250),
        tap(this.setMasonry),
        takeUntil(this.unsubscribe)
      )
      .subscribe();
  }

  mounted(): void {
    //set an interval just in case masonry runs before all dom content is loaded
    //this should prevent the footer from going behind the content
    this.setMasonry();
    setTimeout(() => this.setMasonry(), 1000);
    this.masonryTick = setInterval(() => this.setMasonry(), 1000);
  }

  destroyed(): void {
    this.unsubscribe.next(true);
    this.unsubscribe.complete();
    clearInterval(this.masonryTick);
  }

  loaded({ cardId, hash }): void {
    this.mediaLoaded.push(`${cardId}_${hash}`);

    const mediaToLoadSortedString = this.mediaToLoad.sort().toString();
    const mediaLoadedSortedString = this.mediaLoaded.sort().toString();

    if (mediaToLoadSortedString === mediaLoadedSortedString) {
      this.setMasonry();
      clearInterval(this.masonryTick);
    }
  }

  public setMasonry(): void {
    this.masonry = new Masonry(".masonry", {
      itemSelector: ".two-columns",
      columnWidth: ".two-columns-sizer",
      gutter: 12,
      percentPosition: true,
    });
  }

  public getMediaPath(url: string): string {
    return blobDataPath + url;
  }

  public getClass(cardType: string): string {
    switch (cardType) {
      case "MEDIA_ONLY_WIDE":
        return "responsive wide";
      case "MEDIA_ONLY_TALL":
        return "tall";
      default:
        return "";
    }
  }
}
