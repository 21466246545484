












































































































import { Component, Prop, Vue } from "vue-property-decorator";

import ShareButtonComponent from "@/components/products/buttons/ShareButton.vue";
import ActionBarComponent from "@/components/products/ActionBar.vue";

import { blobDataPath, getExploreMoreLink } from "@/util";

import { DCSLanguageService } from "@/services";
import { ProductDetail } from "@/types";

@Component({
  components: {
    ShareButtonComponent,
    ActionBarComponent
  }
})
export default class CardComponent extends Vue {
  @Prop()
  public product!: ProductDetail;

  public blobDataPath = blobDataPath;
  public getExploreMoreLink = getExploreMoreLink;

  public DCSLanguageService = new DCSLanguageService();

  public customTracker(product): void {
    if (product.buttonLink) {
      this["$appInsights"].appInsights.trackEvent({
        name: `${product.heading} - CTA Button`,
        properties: {
          page: "Product Overview Page",
          taggedItemType: "CTA",
          taggedItemText: "PRODUCT NAME - CTA Button",
        },
      });
      //eslint-disable-next-line
      (window as any).dataLayer.push({
        event: "Link Tracking",
        link: {
          linkName: `${product.heading}`, // usually link text or other identifier
          linkPosition: "CTA Button", // location of link on page
          linkPage: product.heading, // usually `page.pageInfo.pageName` from `Page Viewed` event
        },
      });
    }
  }

  onImgLoad(): void {
    this.$emit("loaded", true);
  }
}
