

















import { Component, Vue } from "vue-property-decorator";
import { DCSLanguageService } from "@/services";

const AppGuideButtonProps = Vue.extend({
  props: {
    applicationLink: {
      type: String,
      default: null,
    },
    color: {
      type: String,
      default: "primary"
    }
  },
});

@Component
export default class AppGuideButtonComponent extends AppGuideButtonProps {
  public DCSLanguageService = new DCSLanguageService();
}
