var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wrapper" },
    [
      _c(
        "div",
        { staticClass: "masonry" },
        [
          _c("div", { staticClass: "two-columns-sizer" }),
          _vm._l(_vm.template.cards, function(card) {
            return _c(
              "div",
              {
                key: card.id,
                class: "column two-columns " + _vm.getClass(card.type)
              },
              [
                card.type === "TEXT_WITH_MEDIA" ||
                card.type === "TEXT_ONLY" ||
                card.type === "TEXT_ONLY_SMALL"
                  ? _c("StandardCardComponent", {
                      attrs: {
                        product: card.productDetail,
                        "card-id": card.id,
                        "resize-masonry": _vm.setMasonry,
                        videos: card.videos
                      },
                      on: { loaded: _vm.loaded }
                    })
                  : _vm._e(),
                card.type === "MEDIA_ONLY_TALL" ||
                card.type === "MEDIA_ONLY_WIDE"
                  ? _c("MediaOnlyComponent", {
                      attrs: { media: card.media, "card-id": card.id },
                      on: { loaded: _vm.loaded }
                    })
                  : _vm._e()
              ],
              1
            )
          })
        ],
        2
      ),
      _c(
        "v-row",
        { staticClass: "stacked" },
        _vm._l(_vm.template.cards, function(card) {
          return _c(
            "v-col",
            { key: card.id, attrs: { cols: "12" } },
            [
              card.type === "TEXT_WITH_MEDIA" ||
              card.type === "TEXT_ONLY" ||
              card.type === "TEXT_ONLY_SMALL"
                ? _c("StandardCardComponent", {
                    attrs: {
                      product: card.productDetail,
                      "card-id": card.id,
                      "resize-masonry": _vm.setMasonry,
                      videos: card.videos
                    },
                    on: { loaded: _vm.loaded }
                  })
                : _vm._e(),
              card.type === "MEDIA_ONLY_TALL" || card.type === "MEDIA_ONLY_WIDE"
                ? _c("MediaOnlyComponent", {
                    attrs: {
                      media: card.media,
                      poster: card.mediaPoster,
                      "card-id": card.id
                    },
                    on: { loaded: _vm.loaded }
                  })
                : _vm._e()
            ],
            1
          )
        }),
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }