import { DCSLanguageService } from "@/services";
import { DistributorDetails, DistributorDetailsWithLinks } from "@/types/distributor-details.interface";
import { http } from "../http-client";
import { defer, Observable } from "rxjs";
import { map } from "rxjs/operators";
import { AxiosResponse } from "axios";
import { serializeStrapiResponse } from "@/util";

export class DistributorService {
    private DCSLanguageService = new DCSLanguageService();

    public getDistributorDetails(): Observable<DistributorDetails[]> {
        return defer(() => http.get<DistributorDetails[]>(`/distributor-details`)).pipe(
            map((res: AxiosResponse<unknown>) => res.data),
            map((distributors) => serializeStrapiResponse(distributors) as DistributorDetails[])
        );
    }

    public getDistributorDetailsByLocaleWithProductLinks(productId: number): Observable<DistributorDetailsWithLinks[]> {
        const languageID = this.DCSLanguageService.getDCSLanguage().identifier;
        return defer(() => http.get(`/dcs-language/distributor-details/${languageID}/product/${productId}`)).pipe(
            map((res: AxiosResponse<unknown>) => res.data),
            map((distributors) => serializeStrapiResponse(distributors) as DistributorDetailsWithLinks[])
        );
    }

    public getDistributorById(id: number): Observable<DistributorDetails> {
        return defer(() => http.get(`/distributor-details/${id}`)).pipe(
          map((res: AxiosResponse<unknown>) => res.data),
          map((distributor) => serializeStrapiResponse(distributor) as DistributorDetails)
        );
    }
}